import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
    {
        path: '/saude-mental/painel/pesquisa',
        name: 'pesquisa-painel-empresa',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloSaudeMental.painelPorEmpresa,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental SESI - Painel'
        },
        component: () =>
        import ('@/views/pages/dashboard/saude-mental/PesquisaDashboardEmpresa.vue'),
    },
    {
      path: '/saude-mental/painel/pesquisa/tela-cheia',
      name: 'painel-empresa-saude-mental-tela-cheia',
      meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloSaudeMental.painelPorEmpresa,
          action: $regraAcao.visualizar,
          layout: 'full',
          title: 'Saúde Mental SESI - Painel Tela Cheia'
      },
      component: () =>
      import ('@/views/pages/dashboard/saude-mental/PesquisaDashboardEmpresaTelaCheia.vue'),
    },
    {
        path: '/planeja-saude/painel/pesquisa',
        name: 'pesquisa-painel-planeja-empresa',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloPlanejaSaude.painelPorEmpresa,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Planeja Saúde - Painel'
        },
        component: () =>
        import ('@/views/pages/dashboard/planeja-saude/PesquisaDashboardPlanejaEmpresa.vue'),
    },
    {
      path: '/sesi-outros-produtos',
      name: 'sesi-listar-outros-produtos',
      meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.portal.outrosProdutos.sesi,
          action: $regraAcao.visualizar,
          layout: 'normal',
          title: 'SESI - Outros Produtos'
      },
      component: () =>
          import ('@/views/pages/outros-produtos/usuario-sesi/listagemOutrosProdutos.vue'),
    }
]
