import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
    {
      path: '/',
      name: 'bem-vindo',
      component: () => import('@/views/pages/miscellaneous/BemVindo.vue'),
      meta: {
        resource: $regraEntidade.rotas.protegida,
        subject: $regraEntidade.portal.inicio.acessoGeral,
        action: $regraAcao.visualizar,
        layout: 'normal',
        title: 'Início'
      },
    },
  ]
