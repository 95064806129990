import provider from '@/store'

const state = {
  objectDashboard: null
}

const mutations = {
  SET_OBJECT_DASHBOARD(mutationState, data){
      mutationState.objectDashboard = data
  }
};

const actions = {
  setObjectDashboard(data) {
    provider.commit("dashboardFullScreenState/SET_OBJECT_DASHBOARD", data);
  }
}

const getters = {
  getObjectDashboard() {
    return provider.state.dashboardFullScreenState.objectDashboard;
  }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
