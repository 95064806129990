
export default [
    {
        path: '/cora-aplicacoes/:aplicacao?',
        name: 'aplicacao-cora',
        meta: {
            layout: 'full',
            title: 'Cora'
        },
        component: () => import ('@/views/pages/cora/CoraAplicacao/Cora.vue')
    },

    {
        path: '/cora/:chave?',
        name: 'cora',
        meta: {
            layout: 'full',
            title: 'Cora'
        },
        component: () => import ('@/views/pages/cora/Cora/Cora.vue')
    },

]
