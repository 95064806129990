import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
    {
        path: "/minha-conta/meus-dados",
        name: "meusDados",
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuSuperior.minhaConta,
            action: $regraAcao.visualizar,
            layout: 'normal',
        },
        component: () =>
            import ("@/views/pages/minha-conta/MinhaContaGeral.vue"),
    },
    {
        path: "/minha-conta/seguranca",
        name: "seguranca",
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuSuperior.minhaConta,
            action: $regraAcao.visualizar,
            layout: 'normal'
        },
        component: () =>
            import ("@/views/pages/minha-conta/MinhaContaGeral.vue"),
    },
    {
        path: "/minha-conta/notificacoes",
        name: "notificacoes",
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuSuperior.notificacoes,
            action: $regraAcao.visualizar,
            layout: 'normal'
        },
        component: () =>
            import ("@/views/pages/minha-conta/MinhaContaGeral.vue"),
    },
];
