import { $regraAcao, $regraEntidade } from "@/custom-enum/regras-enum";

export default [{
        path: '/usuarios-sesi',
        name: 'usuarios-sesi',
        component: () =>
            import ('@/views/pages/usuarios-sesi/UsuariosSesi'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.usuariosSesi,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Usuários Sesi'
        },
    },
    {
        path: '/ciclos',
        name: 'ciclos',
        component: () =>
            import ('@/views/pages/ciclos/ListagemCiclos'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.ciclos,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Ciclos'
        },
    },
    {
        path: '/ciclos',
        name: 'ciclos-planeja-saude',
        component: () =>
            import ('@/views/pages/ciclos/ListagemCiclos'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.ciclos,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Ciclos'
        },
    },
    {
        path: '/ciclos',
        name: 'ciclos-saude-mental',
        component: () =>
            import ('@/views/pages/ciclos/ListagemCiclos'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.ciclos,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Ciclos'
        },
    },
    {
        path: '/ciclos/cadastrar',
        name: 'ciclos-cadastrar',
        component: () =>
            import ('@/views/pages/ciclos/CadastrarCiclos'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.ciclos,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Ciclos - Cadastrar'
        },
    },
]
