import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn} from '@/auth/utils'
import inicio from './routes/inicio'
import erros from './routes/erros'
import meusProdutos from './routes/meusProdutos'
import empresas from './routes/empresas'
import perfil from './routes/perfil'
import auth from './routes/auth'
import diversos from './routes/diversos'
import configuracoes from './routes/configuracoes'
import usuariosSesi from './routes/usuarios-sesi'
import ajudaSuporte from './routes/ajuda-suporte'
import minhaConta from './routes/minhaConta'
import relatorioAcompanhamento from './routes/relatorio-acompanhamento'
import aplicacoes from './routes/aplicacoes'
import cora from './routes/cora'
import questionarios from './routes/questionarios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'bem-vindo' } },
    ...inicio,
    ...meusProdutos,
    ...empresas,
    ...perfil,
    ...auth,
    ...erros,
    ...diversos,
    ...configuracoes,
    ...usuariosSesi,
    ...minhaConta,
    ...ajudaSuporte,
    ...relatorioAcompanhamento,
    ...aplicacoes,
    ...cora,
    ...questionarios,
    {
      path: '*',
      redirect: { name: 'error-404' },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const userData = getUserData();
  const isLoggedIn = isUserLoggedIn();

  //Redirect if logged in
  if (to.meta.resource === "AUTH" && isLoggedIn) {
      return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Se tem ACL quer dizer que alguém está tentando acessar uma rota protegida com um ou mais perfis
  if(to.meta.resource === "ACL") {
    // Redireciona o usuario que nao está logado
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (!canNavigate(to)) {
      return next({ name: 'misc-not-authorized' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, _from) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
    Vue.nextTick(() => {
        document.title = to.meta.title || 'Cliente Sesi+Saúde';
    });
})

export default router
