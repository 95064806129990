import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import createPersistedState from 'vuex-persistedstate'
import sessions from './sessions'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import modal from './modal'
import notifications from './notifications'
import outrosProdutosState from './outrosProdutosState'
import aplicacaoState from './aplicacaoState'
import dashboardFullScreenState from './dashboardFullScreenState'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sessions,
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    modal,
    notifications,
    outrosProdutosState,
    aplicacaoState,
    dashboardFullScreenState
  },
  plugins: [
    createPersistedState({
      paths: [
        'sessions',
        'outrosProdutosState',
        'aplicacaoState',
        'dashboardFullScreenState'
      ],
    }),
  ],

  strict: process.env.DEV,
})
