import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
    {
        path: '/grupo/:idGrupo/matriz',
        name: 'matriz',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.grupo.matriz,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Matriz'
        },
        component: () =>
            import ('@/views/pages/grupos/GrupoDetalhe.vue'),
    },
    {
        path: '/grupo/:idGrupo/filiais/:idEmpresa?',
        name: 'filiais',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.grupo.filial,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Filial'
        },
        component: () =>
            import ('@/views/pages/grupos/GrupoDetalhe.vue'),
    },
    {
        path: '/grupo/:idGrupo/pessoas',
        name: 'pessoas',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.grupo.pessoas,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Pessoas'
        },
        component: () =>
            import ('@/views/pages/grupos/GrupoDetalhe.vue'),
    },
    {
        path: '/grupo/:idGrupo/parametros',
        name: 'parametros',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.grupo.parametros,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Parâmetros'
        },
        component: () =>
            import ('@/views/pages/grupos/GrupoDetalhe.vue'),
    },
    {
        path: '/grupo/:idGrupo/modulos',
        name: 'modulos',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.grupo.modulos,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Módulos'
        },
        component: () =>
            import ('@/views/pages/grupos/GrupoDetalhe.vue'),
    }
]
