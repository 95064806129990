import provider from '@/store';
import axios from "@/libs/axios";
import rotasAPI from "@/router/rotasAPI";
import { initialAbility } from "@/libs/acl/config";
import api from "@/libs/api";

const baseURL = process.env.VUE_APP_APP_URL

export const getProjectStatus = () => {
  return process.env.VUE_APP_CURRENT_ENV ? process.env.VUE_APP_CURRENT_ENV : "desenvolvimento";
}


const login = `${baseURL}/login`
const notAuthorized = `${baseURL}/pagina-nao-autorizada`

export const getHomeRouteForLoggedInUser = userRole => {
    if (Array.isArray(userRole.perfil) && userRole.perfil.length) {
        return { name: 'bem-vindo' }
    }

    provider.commit('sessions/LOGOUT_USER')

    return { name: 'auth-login' }
}

export const isUserLoggedIn = () => {
    return provider.getters['sessions/isLoggedIn']
}

export const getToken = () => {
    return provider.state.sessions.token
}

export const getUserData = () => {
    return provider.state.sessions.userData
}

export const startCount = () => {
    provider.dispatch('sessions/startCount').then()
}

export const logoutUser = () => new Promise((resolve, reject) => {
    axios.get(rotasAPI.Logout)
        .then(response => {
            provider.dispatch('sessions/logout').then()
            this.$ability.update(initialAbility)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const logoutUserRedirectToLogin = () => {
    provider.dispatch('sessions/logout').then()
    location.href = login
}

export const redirectToNotAuthorized = () => {
    location.href = notAuthorized
}

export const setAbility = () => {
    return new Promise(() => {
        api.get(rotasAPI.RegrasUsuarioLogado)
            .then(response => {
                const ability = response.data
                provider.commit('sessions/SET_ABILITY', ability)
            })
    })
}

export const updateAbilities = (abilities) => {
  provider.commit('sessions/SET_ABILITY', abilities)
}

