<template>
  <div
    id="notificacao-modal"
    v-if="notificacaoSelecionada != null && hasPerfilSaude"
    :class="modalClass"
    :key="ultimaAtualizacao"
  >
    <transition name="modal">
      <div id="modal-global-notificacao" :class="notificacaoSelecionada.classe">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title">
                    <img
                      v-if="notificacaoSelecionada.icon != null"
                      :src="notificacaoSelecionada.icon"
                      :alt="notificacaoSelecionada.titulo"
                    />
                    {{ notificacaoSelecionada.titulo }}
                  </h3>

                  <button
                    type="button"
                    aria-label="Close"
                    class="close"
                    @click="visibilityModal(false)"
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body text-center">
                  <p v-text="TextoCorpoNotificacao(notificacaoSelecionada)"></p>

                  <div class="text-center">
                    <b-button
                      id="modal-global-notificacao-ver-mais"
                      @click="handleNotificacaoRespostaRedirect"
                      >Ver mais</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";
import { TIPONOTIFICACAO } from "@/custom-enum/tipo-notificacao.js";

import meusProdutos from "@/router/routes/meusProdutos";
import auth from "@/router/routes/auth";
import erros from "@/router/routes/erros";
import minhaConta from "@/router/routes/minhaConta";

const saudeMentalRespostasCompartilhadas = meusProdutos.find(
  (route) => route.name == "saude-mental-respostas-compartilhadas"
);

const saudeMentalRespostasCompartilhadasRiscoAVida = meusProdutos.find(
  (route) => route.name == "saude-mental-respostas-compartilhadas-risco-a-vida"
);

export default {
  components: {
    BModal,
    BButton,
  },
  data() {
    return {
      usuarioLogado: this.$store.state.sessions.userData,
      hasPerfilSaude: false,
      routesIgnore: [],
      lastRoute: null,
      showModal: true,
      modalClass: "",
      processed: [],
      modal: {
        itens: [],
      },
    };
  },
  beforeMount() {
    this.routesIgnore = [...auth, ...erros, ...minhaConta.slice(1)].map(
      (route) => route.name
    );
    this.routesIgnore.push("saude-mental-respostas-compartilhadas");
    this.routesIgnore.push("cora");
    this.routesIgnore.push(
      "saude-mental-respostas-compartilhadas-risco-a-vida"
    );
  },
  mounted() {
    this.setHasSaude();
  },
  methods: {
    TextoCorpoNotificacao(notificacao) {
      let retorno = notificacao.conteudo;
      if (
        notificacao.id_tipo_notificacao ==
        TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA
      ) {
        retorno += " Clique em Ver mais para visualizar as respostas com risco à vida.";
      }

      return retorno;
    },
    setHasSaude(){
      const { role } = this.usuarioLogado;
      this.hasPerfilSaude = role.perfil.some((item) => item.id_perfil === 5);
    },
    setNotificacoes() {
      this.$helpers.delay(1000).then(() => {
        this.notificationsAlertaDeRiscoAVida =
          this.$store.getters[
            "notifications/getNotificationsAlertaDeRiscoAVida"
          ];
      });
    },
    setNotificacaoModal(type, route, notificacions = []) {
      if (notificacions.length > 0) {
        notificacions.forEach((notification) => {
          let prioridade = type == "alertaDeRiscoAVida" ? 1 : 2,
            existe =
              this.$helpers.findIndexInArray(
                this.modal.itens,
                notification,
                "id_notificacao_usuario"
              ) > -1;

          if (!existe) {
            this.modal.itens.push({
              ...notification,
              ...{ prioridade, route, type },
            });
          }
        });
      }
    },
    handleNotificacaoRespostaRedirect() {
      let redirect = {
        name: this.notificacaoSelecionada.route.name,
        params: {
          notificationUpdateUserNotificationAsRead: this.notificacaoSelecionada,
        },
      };

      this.$helpers.findIndexInArray(
        this.modal.itens,
        this.notificacaoSelecionada,
        "id_notificacao_usuario"
      );

      this.$router.push(redirect).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    visibilityModal(show = false) {
      this.showModal = show;
      this.modalClass = !show ? "d-none" : "";
    },
    verificaMostraModal() {
      this.visibilityModal(false);
      this.lastRoute = this.$route.name;
      this.$helpers.delay(1000).then(() => {
        this.notificationsAlertaDeRiscoAVida =
          this.$store.getters[
            "notifications/getNotificationsAlertaDeRiscoAVida"
          ];
        this.visibilityModal(
          !this.routesIgnore.includes(this.lastRoute) &&
            this.notificationsAlertaDeRiscoAVida.length > 0
        );
      });      
    },
  },
  computed: {
    ultimaAtualizacao() {
      this.verificaMostraModal();
      return this.$store.getters["notifications/getUpdatedAt"];
    },
    notificationsAlertaDeRiscoAVida: {
      get: function () {
        return this.$store.getters[
          "notifications/getNotificationsAlertaDeRiscoAVida"
        ].itens;
      },
      set: function (newValue) {
        this.setNotificacaoModal(
          "alertaDeRiscoAVida",
          saudeMentalRespostasCompartilhadasRiscoAVida,
          newValue.itens
        );
        return newValue;
      },
    },
    notificacaoSelecionada() {
      let itensFiltereds = [];
      if (this.modal.itens.length > 0) {
        this.modal.itens.forEach((item) => {
          let index = this.$helpers.findIndexInArray(
            this.processed,
            item,
            "id_notificacao_usuario"
          );

          if (index === -1) {
            itensFiltereds.push(item);
          }
        });

        let itens = itensFiltereds.sort((a, b) => a.prioridade - b.prioridade),
          icon = null,
          classe = "respostas_compartilhadas";

        if (itens.length > 0) {
          let selecionada = itens[0];

          if (
            selecionada.id_tipo_notificacao ==
            TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA
          ) {
            icon = require("@/assets/custom-icons/coracaoRiscoVida.svg");
            classe = "alerta_de_risco_a_vida";
          }

          return {
            ...{ icon },
            ...{ classe },
            ...selecionada,
          };
        }
      }
      return null;
    },
  },
  watch: {
    ultimaAtualizacao() {
      this.setNotificacoes();
    },

    $route: {
      immediate: true,
      handler() {
        this.verificaMostraModal();
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/custom/notificacao-modal.scss";
</style>
