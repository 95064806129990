import { $regraAcao, $regraEntidade } from "@/custom-enum/regras-enum";

export default [
    {
        path: '/relatorio-acompanhamento',
        name: 'relatorioAcompanhamento',
        component: () =>
            import ('@/views/pages/relatorio-acompanhamento/FormRelatorioAcompanhamento'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuLateral.configuracoes.relatorioAcompanhamento,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Exportação'
        },
    },
]
