import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
  {
    path: '/empresas',
    name: 'listar-empresas',
    meta: {
      resource: $regraEntidade.rotas.protegida,
      subject: $regraEntidade.portal.buscar,
      action: $regraAcao.visualizar,
      layout: 'normal',
      title: 'Empresas',
    },
    component: () => import('@/views/pages/empresas/ListaEmpresas.vue'),
  },

  {
    path: '/empresas/matriz/criar',
    name: 'empresas-matriz-criar',
    meta: {
      resource: $regraEntidade.rotas.protegida,
      subject: $regraEntidade.portal.cadastrarNova,
      action: $regraAcao.visualizar,
      layout: 'normal',
      title: 'Nova empresa',
    },
    component: () => import('@/views/pages/empresas/InserirEmpresa.vue'),
  },
]
