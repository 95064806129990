import api from '@/libs/api'
import rotasAPI from '@/router/rotasAPI'
import helpers from '@/helpers'

window.Pusher = require('pusher-js')

const defaultCount = parseInt(process.env.VUE_APP_SESSION_EXPIRED) | 7200

const state = {
  count: defaultCount,
  token: null,
  userData: null,
  profiles: [],
  modules: [],
  regions: [],
  refCount: null,
}

const mutations = {
  DEFINE_LOGGED_USER(state, { token, userData }) {
    state.userData = userData
    state.token = token
  },
  RESET_LOGGED_USER(state, { token, userData }) {
    state.userData.auth = userData.auth
    state.userData.id_usuario = userData.id_usuario
    state.userData.email = userData.email
    state.userData.empresa = userData.empresa
    state.userData.colaborador = userData.colaborador
    state.userData.usuario_perfis_empresa = userData.usuario_perfis_empresa
    state.userData.role.perfil = userData.role.perfil
    state.token = token
  },
  DEFINE_NAME_USER(state, name) {
    state.userData.fullName = name
  },

  LOGOUT_USER(state) {
    state.token = null
    state.userData = {}
  },
  START_COUNT(state) {
    if (state.token && state.userData) {
      clearInterval(state.refCount)
      state.count = defaultCount
      state.refCount = null

      state.refCount = setInterval(() => {
        state.count--
      }, 1000)
    }
  },
  STOP_COUNT(state) {
    clearInterval(state.refCount)
    state.count = defaultCount
    state.refCount = null
  },
  SET_COUNT(state, newCount) {
    state.count = newCount
  },
  SET_REF_COUNT(state, newRefCount) {
    state.refCount = newRefCount
  },
  SET_ABILITY(state, data) {
    state.userData.ability = data.ability !== undefined ? data.ability : data
  },
  POPULATE_PROFILES(profiles) {
    this.state.profiles = profiles
  },
  POPULATE_REGIONS(regions) {
    this.state.regions = regions
  },
  POPULATE_MODULES(modules) {
    this.state.modules = modules
  },
}

const actions = {
  login({ commit }, userCredentials ) {
    const route = userCredentials.admin ? rotasAPI.LoginAdmin : rotasAPI.Login

    return new Promise((resolve, reject) => {
      api
        .post(route, userCredentials.userData)
        .then(response => {
          const res = response.data

          commit('DEFINE_LOGGED_USER', {
            token: res.auth.access_token,
            userData: res,
          })

          helpers.PusherAuth(response.data.auth.access_token)

          commit('START_COUNT')
          commit('POPULATE_PROFILES', res.role.perfil)
          commit('POPULATE_REGIONS', res.role.regioes)
          commit('POPULATE_MODULES', res.role.modulos)

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  logout({ commit }) {
    commit('LOGOUT_USER')
    commit('POPULATE_PROFILES', [])
    commit('POPULATE_REGIONS', [])
    commit('POPULATE_MODULES', [])
    commit('STOP_COUNT')
  },

  startCount({ commit }) {
    commit('START_COUNT')
  },
  setCount({ commit }, newCount) {
    commit('SET_COUNT', newCount)
  },

  resetCompany({ commit }, loginData ) {
    commit('RESET_LOGGED_USER', {
      token: loginData.auth.access_token,
      userData: loginData
    })

    helpers.PusherAuth(loginData.auth.access_token)

    commit('START_COUNT')
  }
}

const getters = {
  isLoggedIn: state => Boolean(state.token && state.userData),
  getCount: state => () => state.count,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
