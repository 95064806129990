import axios from 'axios'

const api = axios.create({
    headers: {
        Accept: 'application/json',
        Content: 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    baseURL: process.env.VUE_APP_API_URL
})

export default api
