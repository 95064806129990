export const $regraEntidade = { 
  menuLateral: {
    configuracoes: {
      ciclos: 'MENU_LATERAL_CONFIGURACOES_CICLOS',
      painelPorEmpresa: 'MENU_LATERAL_CONFIGURACOES_PAINEL_POR_EMPRESA',
      painelCursosNR: 'MENU_LATERAL_CONFIGURACOES_CURSOS_NR_PAINEL',
      painelPorEmpresaCursosNR: 'MENU_LATERAL_CONFIGURACOES_CURSOS_NR_PAINEL',
      novaAplicacaoCora: 'MENU_LATERAL_CONFIGURACOES_EXPORTACAO',
      relatorioAcompanhamento: 'MENU_LATERAL_CONFIGURACOES_EXPORTACAO',
    },
    empresas: {
      buscar: 'MENU_LATERAL_EMPRESAS_BUSCAR',
      cadastrarNova: 'MENU_LATERAL_EMPRESAS_CADASTRAR_NOVA'
    },
    header: {
      configuracoes: 'MENU_LATERAL_HEADER_CONFIGURACOES',
      empresas: 'MENU_LATERAL_HEADER_EMPRESAS',
      meusProdutos: 'MENU_LATERAL_HEADER_MEUS_PRODUTOS',
      produtos: 'MENU_LATERAL_HEADER_PRODUTOS',
    },
    inicio: 'MENU_LATERAL_INICIO',
    meusProdutosSaudeMental: {
      painel: 'MENU_LATERAL_MEUS_PRODUTOS_SAUDE_MENTAL_PAINEL',
      politicaDePrivacidade: 'MENU_LATERAL_MEUS_PRODUTOS_SAUDE_MENTAL_POLITICA_DE_PRIVACIDADE',
      respostasCompartilhadas: 'MENU_LATERAL_MEUS_PRODUTOS_SAUDE_MENTAL_RESPOSTAS_COMPARTILHADAS',
      respostasCompartilhadasRiscoVida: 'MENU_LATERAL_MEUS_PRODUTOS_SAUDE_MENTAL_RESPOSTAS_COMPARTILHADAS_RISCO_VIDA',
    },
    meusProdutosPlanejaSaude: {
      politicaDePrivacidade: 'MENU_LATERAL_MEUS_PRODUTOS_PLANEJA_SAUDE_POLITICA_DE_PRIVACIDADE',
      relatorioIndividual: 'MENU_LATERAL_MEUS_PRODUTOS_PLANEJA_SAUDE_RELATORIO_INDIVIDUAL',
      painel: 'MENU_LATERAL_MEUS_PRODUTOS_PLANEJA_SAUDE_PAINEL',
      painelBI:'MENU_LATERAL_MEUS_PRODUTOS_BI_PLANEJA_SAUDE_PAINEL'
    },
    meusProdutosCora: {
      aplicacoes: {
        listagem: 'MENU_LATERAL_PRODUTOS_MODULO_CORA_APLICACOES',
        nova: 'MENU_LATERAL_PRODUTOS_MODULO_CORA_APLICACOES_CADASTRAR_NOVA',
        visualizar: 'MENU_LATERAL_MEUS_PRODUTOS_MODULO_CORA_APLICACOES_VISUALIZAR',
      },
      questionarios: {
        listagem: 'MENU_LATERAL_PRODUTOS_MODULO_CORA_QUESTIONARIOS',
        novo: 'MENU_LATERAL_PRODUTOS_MODULO_CORA_QUESTIONARIOS_CADASTRAR_NOVO'
      },
      meus_produtos: 'MENU_LATERAL_MEUS_PRODUTOS_MODULO_CORA_APLICACOES',
    },
    meusProdutoSaudeMentalProdutos: {
      painelPorEmpresaSaudeMental: 'MENU_LATERAL_CONFIGURACOES_PAINEL_POR_EMPRESA_SAUDE_MENTAL',
      ciclos: 'MENU_LATERAL_CONFIGURACOES_CICLOS',
    },
    meusProdutosPlanejaSaudeSesi: {
      painelPorEmpresaPlanejaSaude: 'MENU_LATERAL_CONFIGURACOES_PAINEL_POR_EMPRESA_PLANEJA_SAUDE',
      painel: 'MENU_LATERAL_PRODUTOS_BI_PLANEJA_SAUDE_PAINEL',
      ciclos: 'MENU_LATERAL_CONFIGURACOES_CICLOS',
      relatorioAcompanhamento: 'MENU_LATERAL_CONFIGURACOES_EXPORTACAO'
    },
    meusProdutosCursosNR: {
      painel: 'MENU_LATERAL_MEUS_PRODUTOS_CURSOS_NR_PAINEL_BI',
      politicaDePrivacidade: 'MENU_LATERAL_MEUS_PRODUTOS_CURSOS_NR_POLITICA_DE_PRIVACIDADE'
    },
    portalUsuariosSesi: 'MENU_LATERAL_PORTAL_USUARIOS_SESI',
    meusProdutosOutrosProdutos: 'MENU_LATERAL_MEUS_PRODUTOS_OUTROS_PRODUTOS',
    geralOutrosProdutos: 'MENU_LATERAL_GERAL_OUTROS_PRODUTOS',
    meusProdutosPowerBI: {
      painel: 'MENU_LATERAL_MEUS_PRODUTOS_POWER_BI_PAINEL',
    },
    meusProdutosSesiFit: {
      painel: 'MENU_LATERAL_PRODUTOS_SESI_FIT_PAINEL',
    },
    meusProdutosSesiFitSesi: {
      painel: 'MENU_LATERAL_MEUS_PRODUTOS_SESI_FIT_PAINEL',
    },
    meusProdutosAcademias:{
      painel: 'MENU_LATERAL_PRODUTOS_ACADEMIAS_COMPANY_PAINEL',
    },
    meusProdutosAcademiasSesi:{
      painel: 'MENU_LATERAL_MEUS_PRODUTOS_ACADEMIAS_COMPANY_PAINEL',
    },
    produtos: {
      painel: 'MENU_LATERAL_PRODUTOS_PAINEL',
    },
  },
  menuSuperior: {
    ajudaESuporte: 'MENU_SUPERIOR_AJUDA_E_SUPORTE',
    minhaConta: 'MENU_SUPERIOR_MINHA_CONTA',
    politicaDePrivacidade: 'MENU_SUPERIOR_POLITICA_DE_PRIVACIDADE',
    notificacoes: 'MENU_SUPERIOR_NOTIFICACOES'
  },
  moduloSaudeMental: {
    adminFaq: 'MODULO_SAUDE_MENTAL_FAQ_ADMIN',
    empresaFaq: 'MODULO_SAUDE_MENTAL_FAQ_EMPRESA',
    painel: 'MODULO_SAUDE_MENTAL_PAINEL',
    painelPorEmpresa: 'MODULO_SAUDE_MENTAL_PAINEL_POR_EMPRESA',
    politicaDePrivacidade: 'MODULO_SAUDE_MENTAL_POLITICA_DE_PRIVACIDADE',
    respostasCompartilhadas: 'MODULO_SAUDE_MENTAL_RESPOSTAS_COMPARTILHADAS', 
    respostasCompartilhadasRiscoVida: 'MODULO_SAUDE_MENTAL_RESPOSTAS_COMPARTILHADAS_RISCO_VIDA',
    miniDashboardPainelInicial: 'MODULO_SAUDE_MENTAL_MINI_DASHBOARD_TELA_INICIAL'
  },
  moduloPlanejaSaude: {
    politicaDePrivacidade: 'MODULO_PLANEJA_SAUDE_POLITICA_DE_PRIVACIDADE',
    relatorioIndividual: 'MODULO_PLANEJA_SAUDE_RELATORIO_INDIVIDUAL',
    painel: 'MODULO_PLANEJA_SAUDE_PAINEL',
    painelBI: 'MODULO_POWER_BI_PLANEJA_SAUDE_PAINEL',
    miniDashboardPainelInicial: 'MODULO_PLANEJA_SAUDE_MINI_DASHBOARD_TELA_INICIAL',
    painelPorEmpresa: 'MODULO_PLANEJA_SAUDE_PAINEL_POR_EMPRESA',
  },
  moduloSesiFIT: {
    painel: 'MODULO_SESI_FIT_PAINEL',
  },
  moduloAcademias: {
    painel: 'MODULO_ACADEMIAS_COMPANY_PAINEL',
  },
  moduloCursosNR: {
    painelSESIOperador:'MODULO_CURSOS_NR_PAINEL_POR_EMPRESA_ACESSO_OPERADOR',
    painelSESIAcessoTotal:'MODULO_CURSOS_NR_PAINEL_POR_EMPRESA_ACESSO_TOTAL',
    painelEmpresa: 'MODULO_CURSOS_NR_PAINEL_BI',
    politicaDePrivacidade: 'MODULO_CURSOS_NR_POLITICA_DE_PRIVACIDADE'
  },
  moduloCora: {
    aplicacao: 'MODULO_CORA_APLICACAO',
    aplicacaoStatus: 'MODULO_CORA_APLICACAO_STATUS',
    questionario: 'MODULO_CORA_QUESTIONARIOS',
    manualCora: 'MODULO_CORA_MANUAL_CORA'
  },
  perfil: {
    ajudaESuporte: 'PORTAL_AJUDA_E_SUPORTE',
    politicaDePrivacidade: 'PORTAL_POLITICA_DE_PRIVACIDADE'
  },
  portal: {
    buscar: 'PORTAL_BUSCAR',
    cadastrarNova: 'PORTAL_CADASTRAR_NOVA',
    faq: {
      admin: 'PORTAL_FAQ_ADMIN',
      empresa: 'PORTAL_FAQ_EMPRESA'
    },
    grupo: {
      filial: 'PORTAL_GRUPO_FILIAL',
      matriz: 'PORTAL_GRUPO_MATRIZ',
      parametros: 'PORTAL_GRUPO_PARAMETROS',
      pessoas: 'PORTAL_GRUPO_PESSOAS',
      modulos: 'PORTAL_GRUPO_MODULOS'
    },
    inicio: {
      acessoGeral: 'PORTAL_INICIO',
      sesi: 'PORTAL_INICIO_SESI',
      empresa: 'PORTAL_INICIO_EMPRESA'
    },
    usuariosSesi: 'PORTAL_USUARIOS_SESI',
    ajudaESuporte: {
      topico: 'PORTAL_TOPICOS_AJUDA_SUPORTE',
      categoria: 'PORTAL_CATEGORIAS_AJUDA_SUPORTE'
    },
    ciclos: 'PORTAL_MODULOS_CICLOS',
    outrosProdutos: {
      empresa: 'PORTAL_OUTROS_PRODUTOS_EMPRESA',
      sesi: 'PORTAL_OUTROS_PRODUTOS_SESI'
    }
  },
  rotas: {
    autenticacao: 'AUTH',
    protegida: 'ACL',
        publica: 'PUBLIC',
  }
}

export const $regraAcao = {
  atualizar: 'ATUALIZAR',
  inserir: 'INSERIR',
  remover: 'REMOVER',
  visualizar: 'VISUALIZAR'
}