import { $themeConfig } from '@themeConfig' 

const initialState = {
    isMenuExpanded: true,
 };

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed, 
    ...initialState,
  },

  getters: {
    isMenuExpanded: (state) => state.isMenuExpanded,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },

    TOGGLE_MENU(state) {
      state.isMenuExpanded = !state.isMenuExpanded
    },
    SET_MENU_EXPANDED(state, value) {
      state.isMenuExpanded = value
    },

    RESET_STATE(state) {
      Object.assign(state, initialState);
    },
  },
  actions: {
    TOGGLE_MENU({ commit }) {
      commit('TOGGLE_MENU')
    },
    setMenuExpanded({ commit }, value) {
      commit('SET_MENU_EXPANDED', value)
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
}
