import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMask, { VueMaskFilter, VueMaskDirective } from 'v-mask'
import money from 'v-money'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VuePdfApp from 'vue-pdf-app'

import EventHub from 'vue-event-hub';

import GAuth from 'vue-google-oauth2'

const googleAuthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
  fetch_basic_profile: true,
}

Vue.use(GAuth, googleAuthOption)
Vue.use(VueMask);

Vue.directive('mask', VueMaskDirective)
Vue.use(money, {precision: 4})


// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'

//custom
import api from '@/router/rotasAPI.js';
import helpers from '@/helpers';

const plugin = {
    install() {
        Vue.prototype.$api = api
        Vue.prototype.$helpers = helpers
    }
}
Vue.use(plugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Custom directives
Vue.filter('VMask', VueMaskFilter)

Vue.component('vue-pdf-app', VuePdfApp)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(EventHub);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
