import provider from '@/store'

const state = {
  productsList: null,
  productsListFilter: '',
  productEdit: null
}

const mutations = {
  SET_PRODUCTS_LIST(mutationState, newList){
      mutationState.productsList = newList
  },
  SET_PRODUCTS_LIST_FILTER(mutationState, newFilter){
    mutationState.productsListFilter = newFilter
  } ,

  RESET_PRODUCT(mutationState){
    mutationState.productEdit = null
  },

  SET_PRODUTC_EDIT(mutationState, newProduct){
    mutationState.productEdit = newProduct
  }
};

const actions = {
  setProductsList({ commit }, newList) {
    commit("SET_PRODUCTS_LIST", newList);
  },
  setProductsListFilter({ commit }, newFilter) {
    commit("SET_PRODUCTS_LIST_FILTER", newFilter);
  },

  setProductEdit({ commit }, newProduct) {
    commit("SET_PRODUTC_EDIT", newProduct);
  },

  resetProduct({ commit }) {
    commit("RESET_PRODUCT");
  }
}

const getters = {
  getProductsList() {
    return provider.state.outrosProdutosState.productsList
  },

  getProduct(){
    return provider.state.outrosProdutosState.productEdit
  },
  getProductsListFilter() {
    return provider.state.outrosProdutosState.productsListFilter
  },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
