export const TIPONOTIFICACAO = {
    IMPORTACAO_DE_PLANILHA: 1,
    RESPOSTAS_COMPARTILHADAS: 2,
    ALERTA_DE_RISCO_A_VIDA: 3,
    PROXIMIDADE_DE_CONCLUSAO_DE_CICLO: 4,
    TERMINO_DE_CICLO: 5,
    CANCELAMENTO_DE_CICLO: 6,
    EXPORTACAO_RESPOSTAS_APLICACAO: 'Exportação de Respostas Aplicação',
    EXPORTACAO_RELATORIO_ACOMPANHAMENTO: 'Exportação de Relatório Acompanhamento',
}
