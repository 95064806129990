import {$regraAcao, $regraEntidade} from "@/custom-enum/regras-enum";

export default [
    {
        path: '/ajuda-suporte-topicos',
        name: 'ajuda-suporte-topicos',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.ajudaESuporte.topico,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Gerenciar tópicos de ajuda'
        },
        component: () => import ('@/views/pages/ajuda-suporte/AjudaSuporteTopicos')
    },
    {
        path: '/ajuda-suporte-categorias',
        name: 'ajuda-suporte-categorias',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.ajudaESuporte.categoria,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Gerenciar categorias de ajuda'
        },
        component: () => import ('@/views/pages/ajuda-suporte/AjudaSuporteCategorias'),
    },
]
