import { $regraAcao, $regraEntidade } from "@/custom-enum/regras-enum";

export default [
    {
        path: '/inserir-questionario',
        name: 'inserir-questionario',
        props: true,
        component: () =>
            import('@/views/pages/questionarios/inserir-questionario/InserirQuestionario.vue'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.questionario,
            action: $regraAcao.inserir,
            layout: 'normal',
            title: 'Inserir Questionario'
        },

    },
    {
        path: '/revisar-questionario',
        name: 'revisar-questionario',
        props: true,
        component: () =>
            import('@/views/pages/questionarios/inserir-questionario/RevisarQuestionario.vue'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.questionario,
            action: $regraAcao.inserir,
            layout: 'normal',
            title: 'Revisar Questionario'
        },
    },
    {
        path: '/editar-feedback',
        name: 'editar-feedback',
        props: true,
        component: () =>
            import('@/views/pages/questionarios/editar-questionario/EditarFeedback.vue'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.questionario,
            action: $regraAcao.inserir,
            layout: 'normal',
            title: 'Editar Questionario'
        },
    },
    {
        path: '/listagem-questionarios',
        name: 'listagem-questionarios',
        props: true,
        component: () =>
            import('@/views/pages/questionarios/listagem-questionarios/ListagemQuestionarios.vue'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.questionario,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Listar Questionarios'
        },
    },
    {
        path: '/editar-questionario',
        name: 'editar-questionario',
        props: true,
        component: () =>
            import('@/views/pages/questionarios/editar-questionario/EditarQuestionario.vue'),
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.questionario,
            action: $regraAcao.inserir,
            layout: 'normal',
            title: 'Editar Questionario'
        },
    },
    {
        path: '/portal/manual-cora',
        name: 'portal-manual-cora',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.manualCora,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Manual de uso e aplicação de questionários'
        },
        component: () =>
            import('@/views/pages/portal/ManualUsoAplicaoQuestionarios.vue'),
    }
]
