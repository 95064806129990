import { $regraAcao, $regraEntidade } from "@/custom-enum/regras-enum";

export default [
    {
        path: '/listagem-aplicacoes',
        name: 'listagem-aplicacoes',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuLateral.meusProdutosCora.aplicacoes.listagem,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Visualizar aplicação'
        },
        component: () => import ('@/views/pages/aplicacoes/listagem-aplicacoes/ListagemAplicacoes.vue')
    },
    {
        path: '/minhas-aplicacoes-cora',
        name: 'minhas-aplicacoes-cora',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuLateral.meusProdutosCora.meus_produtos,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Minhas Aplicações'
        },
        component: () => import ('@/views/pages/aplicacoes/minhas-aplicacoes/ListagemAplicacoes.vue')
    },
    {
        path: '/visualizar-aplicacao',
        name: 'visualizar-aplicacao',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloCora.aplicacao,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Visualizar aplicação'
        },
        component: () => import ('@/views/pages/aplicacoes/visualizar-aplicacao/VisualizarAplicacao.vue')
    },
    {
      path: '/inserir-aplicacao',
      name: 'inserir-aplicacao',
      meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloCora.aplicacao,
          action: $regraAcao.inserir,
          layout: 'normal',
          title: 'Inserir Aplicação'
      },
      component: () => import('@/views/pages/aplicacoes/inserir-aplicacao/InserirAplicacao.vue'),
    },
    {
      path: '/visualizar-aplicacao/pausar-cancelar',
      name: 'visualizar-aplicacao-pausar-cancelar',
      meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloCora.aplicacaoStatus,
          action: $regraAcao.atualizar,
          layout: 'normal',
          title: 'Pausar ou cancelar aplicação'
      },
      component: () => import ('@/views/pages/aplicacoes/visualizar-aplicacao/DadosAplicacaoPausarOuCancelar.vue')
    },
    {
        path: '/dashboard-tela-cheia-pqh/:arquivoAnexo',
        name: 'dashboard-tela-cheia-phq',
        meta: {
            resource: $regraEntidade.rotas.publica,
            layout: 'full',
            //subject: $regraEntidade.moduloCora.aplicacao,
            // action: $regraAcao.visualizar,
            title: 'Dashboard-PHQ-9',
        },
        component: () => import('@/views/pages/aplicacoes/visualizar-aplicacao/DashboardTelaCheia.vue'),
    },
    {
        path: '/dashboard-tela-cheia-dass/:arquivoAnexo',
        name: 'dashboard-tela-cheia-dass',
        meta: {
            resource: $regraEntidade.rotas.publica,
            layout: 'full',
            //subject: $regraEntidade.moduloCora.aplicacao,
            // action: $regraAcao.visualizar,
            title: 'Dashboard-DASS-21',
        },
        component: () => import('@/views/pages/aplicacoes/visualizar-aplicacao/DashboardTelaCheiaDass.vue'),
    },
]
