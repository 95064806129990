<template>
  <div v-if="showModal" class="shadow my-float">
    <div class="message">
      Utilizamos <i>cookies</i> essenciais e tecnologias semelhantes de
      acordo com a nossa
      <strong>
        <a 
          href="https://drive.google.com/file/d/1hC7ZVZGyHzJhAt7c-FCfSW2la9YyeujB/view"
          class="my-float-link"
          target="_blank"
        >
          <u>Política de privacidade</u>
        </a>
      </strong>
      e, ao continuar navegando, você concorda com estas condições.
    </div>
    <button 
      class="my-float-button"
      @click="acceptCookies"
    >
      Entendi
    </button>
  </div>
</template>

<script>
export default {
  name: 'CookieConsentModal',
  data() {
    return {
        showModal: !localStorage.getItem('cookiesAccepted')
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.showModal = false;
    }
  },
};
</script>
<style>
  .my-float{
    position: fixed;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    bottom: 30px;
    width: 36vw;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 10px 15px;
    border-radius: 20px;
    z-index: 100;
    color: white;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .my-float-button{
    background-color: #FFFFFF;
    color: #000000;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    margin: 0 10px;
  }

  .my-float-link{
    text-decoration: underline;
    color: white;
  }
  .my-float-link:hover{
    text-decoration: underline;
    color: white;
  }
  
  @media (max-width: 600px) {
    .my-float{
      flex-direction: column;
      text-align: center;
    }
    .my-float-button{
      margin-top: 20px;
    }
  }
  @media (max-width: 1200px) {
    .my-float{
      width: 60vw;
    }
  }
</style>