export default [{
        path: '/pagina-nao-encontrada',
        name: 'error-404',
        component: () =>
            import ('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            title: 'Página não encontrada'
        },
    },
    //to do not authorized tela
    {
        path: '/pagina-nao-autorizada',
        name: 'misc-not-authorized',
        component: () =>
            import ('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            title: 'Página não autorizada'
        },
    },
    {
        path: '/sessao-expirada',
        name: 'disconnected',
        component: () =>
            import ('@/views/pages/miscellaneous/Disconnected.vue'),
        meta: {
            layout: 'full',
            title: 'Sessão expirada'
        },
    },
    {
        path: '/nao-habilitado',
        name: 'misc-not-enabled',
        component: () =>
            import ('@/views/pages/miscellaneous/NotEnabled.vue'),
        meta: {
            layout: 'full',
            title: 'Não habilitado'
        },
    },
    {
        path: '/sair',
        name: 'sair',
        component: () =>
            import ('@/views/pages/miscellaneous/NotAuthorized.vue'),
        props: true,
        meta: {
            layout: 'full',
            title: 'Sair'
        }
    }
]