import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
    {
        path: '/ajuda-e-suporte/:categoria?',
        name: 'faq-ajuda-e-suporte',
        meta: {
          layout: 'normal'
        },
        component: () =>
            import ('@/views/pages/faq/Faq.vue'),
    },
    {
        path: '/portal/politica-de-privacidade',
        name: 'portal-politica-de-privacidade',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.perfil.politicaDePrivacidade,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Política de privacidade'
        },
        component: () =>
            import ('@/views/pages/portal/PoliticaPrivacidadePortal.vue'),
    },
    {
        path: '/portal/politica-de-privacidade-empresa',
        name: 'portal-politica-de-privacidade-empresa',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.menuLateral.meusProdutosCursosNR.politicaDePrivacidade,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Política de privacidade'
        },
        component: () =>
            import ('@/views/pages/portal/PoliticaPrivacidadePortal.vue'),
    }
    
]
