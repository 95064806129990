import provider from '@/store'

const state = {
  idAplicacao: null
}

const mutations = {
  SET_ID_APLICACAO(mutationState, data){
      mutationState.idAplicacao = data
  }
};

const actions = {
  setIdAplicacao(data) {
    provider.commit("aplicacaoState/SET_ID_APLICACAO", data);
  }
}

const getters = {
  getIdAplicacao() {
    return provider.state.aplicacaoState.idAplicacao;
  }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
