export default {
  namespaced: true,
  state: {
    ModalFormularioEmpresaValores: {},
    EmpresaModalFormularioEstado: false,
    empresa: {},
    tituloModal: "",
    tipoEmpresa: 1,
    editarFilialPelaBuscaGeral: false,
    modalAtualizarMatriz: false,
    modalInserirFilial: false,
    modalAtualizarFilial: false,
  },

  mutations: {
    UPDATE_EMPRESA_MODAL_FORMULARIO_VALORES(state, val) {
      let modulos = [],
        regionais = [];
      if (typeof val.Modulos != "undefined") {
        modulos = val.Modulos;
      }

      if (typeof val.Regionais != "undefined") {
        regionais = val.Regionais.map((regional) => {
          return {
            ativo: regional.ativo,
            descricao: regional.descricao,
            id_regiao: regional.id_regiao,
          };
        });
      }

      state.ModalFormularioEmpresaValores = val;
      state.ModalFormularioEmpresaValores.Modulos = modulos;
      state.ModalFormularioEmpresaValores.Regionais = regionais;

      state.EmpresaModalFormularioEstado = true;
    },

    limparState(state) {
      state.empresa = {};
      state.tituloModal = "";
      state.tipoEmpresa = 1;

      state.editarFilialPelaBuscaGeral = false;

      state.modalAtualizarMatriz = false;
      state.modalInserirFilial = false;
      state.modalAtualizarFilial = false;
    },

    exibirModalAtualizarMatriz(state, val) {
      state.empresa = val.empresa;
      state.tituloModal = val.tituloModal;
      state.tipoEmpresa = val.tipoEmpresa;

      state.modalAtualizarMatriz = true;
    },

    exibirModalInserirFilial(state, val) {
      state.tituloModal = val.tituloModal;
      state.tipoEmpresa = val.tipoEmpresa;

      state.modalInserirFilial = true;
    },

    exibirModalAtualizarFilial(state, val) {
      state.empresa = val.empresa;
      state.tituloModal = val.tituloModal;
      state.tipoEmpresa = val.tipoEmpresa;

      state.modalAtualizarFilial = val.mostrarModal;
      state.editarFilialPelaBuscaGeral = val.editarFilialPelaBuscaGeral
        ? val.editarFilialPelaBuscaGeral
        : false;
    },
  },
  actions: {},
};
